import React from "react"
import TransitionLink from "gatsby-plugin-transition-link"
import styled from "styled-components"
import Flex from "styled-flex-component"

const StyledDeskNav = styled(Flex)`
  display: none;
  @media ${props => props.theme.breakpoints.large} {
    display: block;
  }
`

const StyledMainNavLink = styled(props => <TransitionLink {...props} />)`
  color: white;
  font-size: 18px;
  text-transform: capitalize;
  text-decoration: none;
  margin-left: 1.2rem;
  font-family: ${props => props.theme.fonts.medium};
`

export const MainNav = ({ navItems, menuState }) => {
  return (
    <StyledDeskNav center>
      {navItems.map(item => (
        <StyledMainNavLink
          to={item.page_reference.url}
          exit={{
            length: 0.3,
          }}
          entry={{
            length: 0.3,
            delay: 0.3,
          }}
        >
          {item.link_title.text}
        </StyledMainNavLink>
      ))}
    </StyledDeskNav>
  )
}

import React from "react"
import TransitionLink from "gatsby-plugin-transition-link"
import styled from "styled-components"

const StyledNav = styled.ul`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${props => props.theme.colors.pullColor};
  z-index: 9999;
  padding: 2rem ${props => props.theme.padding.hMobile};
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
  ${props =>
    props.menuState &&
    `
    visibility: visible;
      opacity: 1;
    
  `}

  li {
    list-style-type: none;
    margin: 10px 0;
  }
  a {
    color: #fff;
    font-family: ${props => props.theme.fonts.medium};
    margin: 1rem;
  }
  @media ${props => props.theme.breakpoints.ns} {
    li {
      display: inline-block;
      margin: 13px 20px 13px 0;
    }
  }
`

export const MobileNav = ({ navItems, menuState, onClose }) => {
  return (
    <StyledNav menuState={menuState}>
      {navItems.map(item => (
        <TransitionLink
          to={item.page_reference.url}
          onClick={onClose}
          exit={{
            length: 0.3,
          }}
          entry={{
            length: 0.3,
            delay: 0.3,
          }}
        >
          {item.link_title.text}
        </TransitionLink>
      ))}
    </StyledNav>
  )
}

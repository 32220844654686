import styled from "styled-components"
import is from "styled-is"

// Heading Styles

export const HeadingOne = styled.h1`
  ${is("measureSmall")`
    max-width: 8em;
  `}
  ${is("pullColor")`
    color: ${props => props.theme.colors.pullColor};
  `}
  ${is("bold")`
    font-family: ${props => props.theme.fonts.bold}
  `}
`
export const HeadingTwo = styled.h2`
  ${is("measureSmall")`
    max-width: 8em;
  `}
  ${is("bold")`
    font-family: ${props => props.theme.fonts.medium}
  `}
  ${is("pullColor")`
    color: ${props => props.theme.colors.pullColor};
  `}
`
export const HeadingThree = styled.h3`
font-family: ${props => props.theme.fonts.medium};
  ${is("measureSmall")`
    max-width: 8em;
  `}  
  ${is("bold")`
    font-family: ${props => props.theme.fonts.medium}
  `}
  ${is("pullColor")`
    color: ${props => props.theme.colors.pullColor};
  `}
`
export const HeadingFour = styled.h4`  
  ${is("measureSmall")`
    max-width: 8em;
  `}
  ${is("bold")`
    font-family: ${props => props.theme.fonts.medium}
  `}
  ${is("pullColor")`
    color: ${props => props.theme.colors.pullColor};
  `}
`
export const HeadingFive = styled.h5`
  ${is("measureSmall")`
    max-width: 8em;
  `}
  ${is("bold")`
    font-family: ${props => props.theme.fonts.medium}
  `}
  ${is("pullColor")`
    color: ${props => props.theme.colors.pullColor};
  `}
`
export const HeadingSix = styled.h6`
  ${is("measureSmall")`
    max-width: 8em;
  `}
  ${is("bold")`
    font-family: ${props => props.theme.fonts.medium}
  `}
  ${is("pullColor")`
    color: ${props => props.theme.colors.pullColor};
  `}
`

export const BodyCopy = styled.div`
  font-size: ${props => props.theme.fontSizes.bodyReg};
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  ul,
  ol {
    margin: 0 0 1em 0;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: ${props => props.theme.lineHeights.heading};
  }
  h3,
  h4,
  h5 {
    font-family: ${props => props.theme.fonts.medium};
  }
  a {
    color: inherit;
  }
  ul,
  ol {
    list-style-position: inside;
  }
  p,
  ul,
  ol {
    line-height: ${props => props.theme.lineHeights.standard};
  }
  ${is("centered")`
    text-align:center;
  `}
  @media ${props => props.theme.breakpoints.large} {
    ol,
    ul {
      column-count: 2;
      column-gap: 4rem;
    }
  }
`

import React from "react"
import PropTypes from "prop-types"
import { ThemeProvider } from "styled-components"
import "../css/fonts.css"
import { siteTheme } from "../themes/theme.js"
import { GlobalStyles } from "../css/globalStyles"

import { Header, Footer } from "../components/nav"

const Layout = ({ children }) => {
  return (
    <ThemeProvider theme={siteTheme}>
      <GlobalStyles />
      <Header />
      <main> {children} </main>
      <Footer />
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

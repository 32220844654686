import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import Headroom from "react-headroom"
import TransitionLink from "gatsby-plugin-transition-link"
import { BraveHamburger, MainNav, MobileNav } from "./"
import Logo from "../../svg/logo.svg"

const StyledMenuToggle = styled.button`
  &:focus {
    outline: none;
    background: none;
  }
  @media ${props => props.theme.breakpoints.large} {
    display: none;
  }
`

const StyledHeader = styled(props => <Headroom {...props} />)`
  z-index: 99999 !important;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 999;
    margin-left: auto;
    margin-right: auto;
    max-width: ${props => props.theme.containerWidths.standard};
    padding: 2rem ${props => props.theme.padding.hMobile};
    background: transparent;
    transition: all 0.5s ease;
  }
  .headroom--scrolled {
    header {
      padding: 1rem ${props => props.theme.padding.hMobile};
    }
  }
  .headroom--unfixed {
    header {
      ${props =>
        props.reverseHeader &&
        `
        * {
          color: #ffffff;
        }
        svg {
          fill: #ffffff;
        }
      `}
    }
  }

  .headroom--pinned {
    background: ${props => props.theme.colors.pullColor};
    header {
    }
  }

  @media ${props => props.theme.breakpoints.large} {
    position: absolute;
    top: 0;
    left: 0;
    header {
      padding: 2rem ${props => props.theme.padding.hDesk};
    }
    .headroom--scrolled {
      header {
        padding: 1rem ${props => props.theme.padding.hDesk};
      }
    }
  }
`

const StyledTransitionLink = styled(TransitionLink)`
  width: 45%;
  max-width: 225px;
  svg {
    width: 100%;
  }
`

export const Header = () => {
  const [menuState, setMenuState] = useState(false)

  const { prismicGlobals } = useStaticQuery(graphql`
    query navQuery {
      prismicGlobals {
        data {
          main_nav {
            page_reference {
              url
            }
            link_title {
              text
            }
          }
        }
      }
    }
  `)
  return (
    <>
      <StyledHeader>
        <header>
          <StyledTransitionLink
            to="/"
            exit={{
              length: 0.3,
            }}
            entry={{
              length: 0.3,
              delay: 0.3,
            }}
          >
            <Logo />
          </StyledTransitionLink>
          <MainNav navItems={prismicGlobals.data.main_nav}></MainNav>
          <StyledMenuToggle onClick={() => setMenuState(!menuState)}>
            <BraveHamburger menuState={menuState} />
          </StyledMenuToggle>
        </header>
      </StyledHeader>
      <MobileNav
        navItems={prismicGlobals.data.main_nav}
        menuState={menuState}
        onClose={() => setMenuState(false)}
      />
    </>
  )
}

export default Header

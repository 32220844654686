import React from "react"
import is from "styled-is"
import styled from "styled-components"
import DownArrow from "../../svg/down-arrow.svg"
import RightArrow from "../../svg/right-arrow.svg"

const Button = styled.button`
  display: block;
  position: relative;
  color: ${props => props.theme.colors.pullColor};
  padding: 0.5rem 0.8rem;
  font-family: ${props => props.theme.fonts.medium};
  text-decoration: none;
  font-size: ${props => props.theme.fontSizes.bodyReg};
  line-height: 15px;
  z-index: 0;
  margin-bottom: 2rem;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background-color: lightgray;
    width: 35px;
    height: 100%;
    border-radius: 30px;
    z-index: -1;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
  &:hover:before {
    width: 100%;
  }

  svg {
    margin-left: 10px;
    height: 15px;
    g,
    polygon {
      fill: ${props => props.theme.colors.pullColor};
    }
  }

  ${is("light")`
    color: white;
    &:before {
      background-color: ${props => props.theme.colors.altColor};
    }
    svg g,
    svg g polygon {
      fill:white ;
    }    
  `};
`

export const CircleButtonLight = ({ text }) => {
  return (
    <>
      <Button light>
        {text}
        <RightArrow />
      </Button>
    </>
  )
}

export const CircleButtonDark = ({ text }) => {
  return (
    <>
      <Button dark>
        {text}
        <RightArrow />
      </Button>
    </>
  )
}

export const CircleButtonDownLight = ({ text }) => {
  return (
    <>
      <Button light>
        {text}
        <DownArrow />
      </Button>
    </>
  )
}

export const BasicButton = styled.button`
  background-color: ${props => props.theme.colors.pullColor};
  color: white;
  padding: 0;
  text-decoration: none;
  border-radius: 2rem;
  padding: 0.8rem 4rem;
  line-height: 1;
  font-size: 18px;
  font-family: ${props => props.theme.fonts.medium};
  font-size: ${props => props.theme.fontSizes.bodyReg};
  ${is("margin-top")`
    margin-top:2rem;
  `};
  ${is("margin-bottom")`
    margin-bottom:2rem;
  `};
`

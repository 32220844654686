import styled from "styled-components"
import is from "styled-is"

export const StandardContainer = styled.div`
  padding-left: ${props => props.theme.padding.hMobile};
  padding-right: ${props => props.theme.padding.hMobile};
  max-width: ${props => props.theme.containerWidths.standard};
  margin-left: auto;
  margin-right: auto;
  ${is("narrow")`
    max-width: ${props => props.theme.containerWidths.narrow};
  `};
  ${is("flex")`
    display: flex;
  `};
  ${is("wrap")`
    flex-wrap: wrap;
  `};
  ${is("justifyBetween")`
    justify-content: space-between;
  `};
  ${is("w100")`
    width: 100%;
  `}
  ${is("left")`
    margin-left: 0;
  `}
  ${is("right")`
    margin-right: 0;
  `}
  ${is("wide")`
    max-width: ${props => props.theme.containerWidths.wide};
  `}  
  ${is("pad-tb")`
    padding-top: 2rem;
    padding-bottom: 2rem;
  `}  
  @media ${props => props.theme.breakpoints.large} {
    padding-left: ${props => props.theme.padding.hDesk};    
    padding-right: ${props => props.theme.padding.hDesk};    
    ${is("nopad-tb-l")`
      padding-top: 0;
      padding-bottom: 0;
    `}  
  }  
`

export const FullSliceContainer = styled.div`
  padding: 2rem 0;
  ${is("relative")`
    position:relative;
  `}
  ${is("flex")`
    display: flex;
  `};
   ${is("w100")`
    width: 100%;
  `}
  ${is("nopad")`
    padding:0;
  `}
  @media ${props => props.theme.breakpoints.large} {
    padding: 4rem 0;
    ${is("nopad")`
    padding:0;
  `}
  }
`

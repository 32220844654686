export const siteTheme = {
  breakpoints: {
    ns: "screen and (min-width: 30em)",
    medium: "screen and (min-width: 30em)",
    large: "screen and (min-width: 64em)",
    xl: "screen and (min-width: 94em)",
  },
  colors: {
    docBg: "#E9FFF6",
    textColor: "#004643",
    pullColor: "#004643",
    altColor: "#74B345",
    tintColor: "#E9FFF6",
  },
  fonts: {
    regular: "Eina-Regular",
    italic: "Eina-Italic",
    medium: "Eina-SemiBold",
    bold: "Eina-Bold",
  },
  fontSizes: {
    bodyReg: "18px",
    h1: "calc(30px + 50*(100vw - 320px)/2180)",
    h2: "calc(40px + 15*(100vw - 320px)/2180)",
    h3: "calc(34px + 10*(100vw - 320px)/2180)",
    h4: "calc(24px + 15*(100vw - 320px)/2180)",
    h5: "calc(18px + 5*(100vw - 320px)/2180)",
    h6: "14px",
  },
  lineHeights: {
    standard: "1.9",
    heading: "1.2",
  },
  letterSpacing: {
    standard: "0",
    nav: "0.81px",
  },
  measures: {
    narrow: "20em",
    standard: "30em",
    medium: "35em",
    large: "55em",
  },
  padding: {
    hMobile: "1.5rem",
    hDesk: "4rem",
  },
  containerWidths: {
    narrow: "1100px",
    standard: "1320px",
    medium: "1320px",
    wide: "1500px",
  },
  pageSpacers: {
    small: "20px",
    medium: "60px",
    large: "200px",
  },
}

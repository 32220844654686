import React from "react"
import styled from "styled-components"
import Logo from "../../svg/logo.svg"
import { useStaticQuery, graphql } from "gatsby"
import Flex, { FlexItem } from "styled-flex-component"
import { FooterNav } from "./"

import { StandardContainer } from "../common"

const StyledFooter = styled.footer`
  background-color: ${props => props.theme.colors.pullColor};
  padding-top: 3rem;
  padding-bottom: 3rem;
  display: block;
  width: 100%;

  svg {
    width: 45%;
    max-width: 225px;
  }

  .brave {
    color: #fff;
    font-family: "Georgia";
    display: block;
    padding-top: 1rem;
  }

  ${FlexItem} {
    width: 100%;
  }

  @media ${props => props.theme.breakpoints.large} {
    padding-top: 4rem;
    ${FlexItem} {
      width: auto;
    }
    .brave {
      padding-top: 0;
    }
  }
`

const FooterTextWrapper = styled.div`
  color: white;
  width: 100%;
  margin-bottom: 2rem;
  @media ${props => props.theme.breakpoints.medium} {
    width: 75%;
  }
`

const Divider = styled(StandardContainer)`
  color: white;
  font-size: 12px;
  span {
    margin-top: 3rem;
    border-top: solid 1px #005a56;
    display: block;
    margin-bottom: 2rem;
  }
`

const FooterTextContent = styled.p`
  margin-top: 1rem;
  max-width: ${props => props.theme.measures.narrow};
  line-height: ${props => props.theme.lineHeights.standard};
`

export const Footer = () => {
  const { prismicGlobals } = useStaticQuery(graphql`
    query footerQuery {
      prismicGlobals {
        data {
          footer_text {
            text
          }
          footer_nav {
            link {
              url
              link_type
            }
            link_text {
              text
            }
          }
        }
      }
    }
  `)
  return (
    <StyledFooter>
      <StandardContainer flex justifyBetween w100 wrap>
        <FooterTextWrapper>
          <Logo />
          <FooterTextContent>
            {prismicGlobals.data.footer_text.text}
          </FooterTextContent>
        </FooterTextWrapper>
        <FooterNav items={prismicGlobals.data.footer_nav} />
      </StandardContainer>
      <Divider>
        <span></span>
        <Flex wrap justifyBetween alignCenter>
          <FlexItem>All Rights Reserved Grasslands 2020</FlexItem>
          <FlexItem>
            <a
              rel="noopener noreferrer"
              href="https://bravedigital.nz/"
              target="_blank"
              className="brave"
            >
              This is a Brave site
            </a>
          </FlexItem>
        </Flex>
      </Divider>
    </StyledFooter>
  )
}

import { createGlobalStyle } from "styled-components"

export const GlobalStyles = createGlobalStyle`
     html {
      text-rendering: optimizeLegibility;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      overflow-x: hidden;
      box-sizing: border-box;
      font-family: ${props => props.theme.fonts.regular};
      font-size: ${props => props.theme.fontSizes.bodyReg};
      letter-spacing: 0.004rem;
      overflow-x: hidden;
      background: ${props =>
        props.pageBg ? props.pageBg : props.theme.colors.docBg};
      transition: background-color 1s ease;
      color: ${props => props.theme.colors.textColor};
    }


  * {
    text-decoration:none;
    font-weight: normal;
    word-wrap: break-word;
    box-sizing: border-box;
    font-family: ${props => props.theme.fonts.regular};
    -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  }

  body, h1, h2, h3, h4, h5, h6, p, ol, ul {
    margin: 0;
    padding: 0;
    font-weight: normal;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  strong{
    font-family: ${props => props.theme.fonts.bold};
  }

  button {
    cursor: pointer;
    background: none;
    border: none;
  }
  
  button:focus {
    outline: none;
  }

  /* Headings */

  h1,h2,h3,h4,h5,h6{
    letter-spacing: -0.01em;
    margin: 0.6rem 0;
    > strong{
      font-family: ${props => props.theme.fonts.medium};
    }
  }

  h1 {
    font-size: ${props => props.theme.fontSizes.h1};
    line-height: ${props => props.theme.lineHeights.heading};
    font-family: ${props => props.theme.fonts.bold};
  }
  h2 {
    font-size: ${props => props.theme.fontSizes.h2};
    font-family: ${props => props.theme.fonts.medium};
    line-height: ${props => props.theme.lineHeights.heading};
  }
  h3{
    font-size: ${props => props.theme.fontSizes.h3};
  }
  h4{
    font-size: ${props => props.theme.fontSizes.h4};
  }
  h5{
    font-size: ${props => props.theme.fontSizes.h5};
  }
  h6{
    font-size: ${props => props.theme.fontSizes.h6};
  }



  /* Animations */

  @keyframes fadeInDown {
    from {
      opacity: 0;
      transform: translateY(50px);
    }
    to {
      opacity: 1;
      transform: translate(0px);
    }
  }
  
`

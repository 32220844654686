import React from "react"
import TransitionLink from "gatsby-plugin-transition-link"
import styled from "styled-components"
import { HeadingFour } from "../common/"

const StyledFooterNav = styled.nav`
  list-style-type: none;
  width: 100%;
  color: #fff;
  border-top: solid 1px #005a56;
  padding-top: 1rem;
  @media ${props => props.theme.breakpoints.medium} {
    width: 25%;
    padding-top: 0;
    padding-left: 2rem;
    border-top: none;
    border-left: solid 1px #005a56;
    h4 {
      font-size: 24px;
    }
  }
`

const StyledFooterNavLink = styled(props => <TransitionLink {...props} />)`
  display: block;
  color: white;
  font-size: 18px;
  text-transform: capitalize;
  text-decoration: none;
  margin-bottom: 0.5rem;
`

const StyledExtenalLink = styled.div`
  display: block;
  color: white;
  font-size: 18px;
  text-transform: capitalize;
  text-decoration: none;
  margin-bottom: 0.5rem;
  a {
    color: white;
  }
`

export const FooterNav = ({ items }) => {
  return (
    <StyledFooterNav>
      <HeadingFour bold>Quick Links</HeadingFour>
      {items.map(item => (
        <>
          {item.link.link_type === "Document" ? (
            <StyledFooterNavLink
              to={item.link.url}
              exit={{
                length: 0.3,
              }}
              entry={{
                length: 0.3,
                delay: 0.3,
              }}
            >
              {item.link_text.text}
            </StyledFooterNavLink>
          ) : (
            <StyledExtenalLink>
              <a href={item.link.url} target="_blank" rel="noopener noreferrer">
                {item.link_text.text}
              </a>
            </StyledExtenalLink>
          )}
        </>
      ))}
    </StyledFooterNav>
  )
}
